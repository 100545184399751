import React, { Component, useEffect } from 'react';
import {BrowserRouter as Router, Route, Switch, useLocation, withRouter} from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import { config } from "./config";
import { Redirector } from "./pages/Redirector";
import CommonHeader from "./components/CommonHeader/CommonHeader";
import { DashboardPage } from "./pages/DashboardPage";
import { InvitationPage } from "./pages/InvitationPage";
import { InstancesPage } from './pages/InstancesPage';
import { Logout } from "./pages/Logout";
import { datadogRum } from '@datadog/browser-rum';
import { FeatureFlagWrapper } from "./components/FeatureFlagWrapper/FeatureFlagWrapper";
import {useQuery} from "./hooks/useQuery";
import OktaOptionsBuilder from './utils/oktaOptionsBuilder';

datadogRum.init({
    applicationId: config.DATADOG_APP_ID!,
    clientToken: config.DATADOG_CLIENT_TOKEN!,
    site: 'us3.datadoghq.com',
    service: 'home-app',
    env: process.env.REACT_APP_ENV || "local",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

const oktaAuth = new OktaAuth({
    issuer: config.OKTA_ISSUER as string,
    clientId: config.CLIENT_ID,
    redirectUri: window.location.origin + '/callback',
    scopes: config.SCOPE
});

function App() {
    const location = useLocation();
    const query = useQuery();

    const restoreOriginalUri = async (_oktaAuth: any, originalUri?: string) => {
        const uri = originalUri || window.location.origin;
        window.location.replace(uri.endsWith('/') ? uri : `${uri}/`);
        // history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    function onAuthRequired(_oktaAuth: OktaAuth){
        const helper = new OktaOptionsBuilder();
        const options = helper.buildOktaOptions(query);
        oktaAuth.signInWithRedirect(options);
    }

    useEffect(() => {
        const viewName = (location.pathname.endsWith('/') ?
            location.pathname.slice(0, -1) :
            location.pathname).split("/").pop();
        datadogRum.startView({
            name: viewName || "dashboard"
        });
    }, [location.pathname]);

    return (
        <Router>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired} >
                <FeatureFlagWrapper>
                    <Switch>
                        <Route path="/accept-invitation" render={() => (
                            <>
                                <CommonHeader />
                                <InvitationPage />
                            </>
                        )} />
                        <Route path="/logout" render={() => (
                            <>
                                <CommonHeader />
                                <Logout />
                            </>
                        )} />
                        <Route path="/callback" component={LoginCallback} />
                        <SecureRoute path="/" exact render={() => (
                            <>
                                <CommonHeader navigateOnOrgChange />
                                <Redirector />
                            </>
                        )} />
                        <SecureRoute path="/:organizationId">
                            <>
                                <CommonHeader />
                                <Switch>
                                    <SecureRoute path="/:organizationId/dashboard" exact component={DashboardPage} />
                                    <SecureRoute path="/:organizationId/:productName/instances" component={InstancesPage} />
                                    <Route path="*" render={() => (
                                        <>
                                            <opti-error-404></opti-error-404>
                                        </>
                                    )} />
                                </Switch>
                            </>
                        </SecureRoute>
                        <Route path="*" render={() => (
                            <>
                                <CommonHeader navigateOnOrgChange/>
                                <opti-error-404></opti-error-404>
                            </>
                        )}/>
                    </Switch>
                </FeatureFlagWrapper>
            </Security>
        </Router>
    );
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
    render() {
        return (
            <Router>
                <AppWithRouterAccess/>
            </Router>
        );
    }
}

export default RouterApp;