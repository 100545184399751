import { useOktaAuth } from "@okta/okta-react";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { Button, Typography } from "@optimizely/axiom";
import { Link } from "react-router-dom";

export const Logout = () => {
    const { authState, oktaAuth } = useOktaAuth();

    if (authState?.isAuthenticated) {
        oktaAuth.signOut().then(() => {
            oktaAuth.tokenManager.clear();
        });
        return (<LoadingIndicator />)
    }

    return (
        <div className="flex flex-column push-quad--top align-items-center">
            <Typography type="body">
                You&apos;ve successfully logged out.
            </Typography>
            <Link to="/">
                {/* eslint-disable-next-line react/style-prop-object */}
                <Button style="highlight" isLink>
                    Login
                </Button>
            </Link>
        </div>
    )

};