import { Grid, GridCell, GridContainer, Typography } from "@optimizely/axiom";
import { ProductCard } from "../ProductCard/ProductCard";
import styles from "./DashboardLayout.module.scss"

type DashboardLayoutProps = {
    name: string;
    email: string;
    products: any;
}

export const DashboardLayout = ({ name, email, products }: DashboardLayoutProps) => {
    const [userFirstName] = name?.split(" ") || [];
    const welcomeMessage = Boolean(userFirstName) ? `Welcome back, ${userFirstName}!` : "Welcome back!";

    return (
        <GridContainer className={styles["dashboard-layout"]}>
            {/* TODO: Eventually, may want to refactor this out into its own component */}
            <Grid className={`${styles["dashboard-layout__header"]} flex flex-column`}>
                <Typography type="header2">{welcomeMessage}</Typography>
                <Typography type="body">Choose a product to get started.</Typography>
            </Grid>
            {/* ---------------------------------------------------------------------- */}
            <Grid className={styles["dashboard-layout__product-grid"]}>
                {Object.keys(products || {}).map(product => {
                    const productInstances = products[product] || [];
                    return (
                        <GridCell xlarge={4} large={4} medium={4} small={4} key={product}>
                            <ProductCard email={email} product={product} instances={productInstances} />
                        </GridCell>
                    );
                })}
            </Grid>
        </GridContainer>
    );
};

DashboardLayout.displayName = "DashboardLayout";