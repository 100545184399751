import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { config } from "../../config";
import { useEffect, useRef, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Profile } from "../../types/Profile.type";

const optimizelyClient = createInstance({ sdkKey: config.OPTIMIZELY_SDK_KEY });
function isClientValid() {
    return optimizelyClient.getOptimizelyConfig() !== null;
}

export const FeatureFlagWrapper = ({ children }: { children: React.ReactNode }) => {
    const [isClientReady, setIsClientReady] = useState(false);
    const [profile, setProfile] = useState<null | Profile>(null);
    const { authState } = useOktaAuth();
    const { idToken, accessToken: at } = authState || {};
    const { accessToken } = at || {};
    const { claims } = idToken || {};
    const { email = "" } = claims || {};
    const fetchedRef = useRef<null | Profile>(null)

    useEffect(() => {
        if (profile || !(accessToken && email && isClientReady)) return;
        if (email === fetchedRef.current?.Email && !profile) {
            setProfile(fetchedRef.current);
        } else {
            fetch(`${config.IDX_SERVICE_URL}/api/users/${email.toLowerCase()}/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            ).then(res => res.json()).then(res => {
                fetchedRef.current = profile;
                setProfile(profile);
            }).catch(error => {
                console.error("failed getting profile: ", error);
            });
        }

    }, [accessToken, email, isClientReady, profile]);

    optimizelyClient.onReady().then(() => {
        isClientValid() && setIsClientReady(true);
    });

    if (!email) {
        // don't track users we don't know.
        return children;
    }


    return (
        <OptimizelyProvider optimizely={optimizelyClient} user={{
            id: profile ? profile.Id : email,
            attributes: {
                email: email.toLowerCase(),
                homeOrganizationId: profile ? profile.HomeOrganizationId : ''
            }
        }}>
            {children}
        </OptimizelyProvider>
    )
};
